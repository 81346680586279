<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    :src="image"
    app
    xcolor="grey darken-2"
    dark
    floating
    mobile-breakpoint="991"
    persistent
    width="205"
    clipped
  >
    <template #img="attrs">
      <v-img
        v-bind="attrs"
        gradient="to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)"
      />
    </template>

    <v-divider class="mx-3 mb-3" />

    <v-list
      nav
      subheader
    >
      <!-- Bug in Vuetify for first child of v-list not receiving proper border-radius -->
      <div />

      <template
        v-for="(link, i) in links"
      >
        <v-list-item
          v-if="link.to && $auth.check(link.auth)"
          :key="'i' + i"
          :to="link.to"
          active-class="primary white--text"
        >
          <v-list-item-action>
            <v-icon v-text="link.icon" />
          </v-list-item-action>

          <v-list-item-title v-text="link.text" />
        </v-list-item>
        <v-divider
          v-else-if="link.divider && $auth.check(link.auth)"
          :key="'d' + i"
        />
        <v-subheader
          v-if="link.subheader && !link.links && $auth.check(link.auth)"
          :key="'s' + i"
          v-text="link.subheader"
        />
        <v-list-group
          v-if="link.links"
          :key="'g' + i"
          :value="link.value"
        >
          <template #activator>
            <v-list-item-icon>
              <v-icon
                small
                v-text="link.icon"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle v-text="link.subheader" />
            </v-list-item-content>
          </template>
          <template
            v-for="(sublink, i) in link.links"
          >
            <v-list-item
              v-if="sublink.to && $auth.check(sublink.auth)"
              :key="'i' + i"
              :to="sublink.to"
              active-class="primary white--text"
              :dense="link.dense"
              exact-path
            >
              <v-list-item-action>
                <v-icon
                  :small="link.dense"
                  v-text="sublink.icon"
                />
              </v-list-item-action>

              <v-list-item-title v-text="sublink.text" />
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>
    <template #appendX>
      <v-toolbar
        v-if="$auth.user()"
        dense
      >
        <v-menu
          top
          offset-y
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-badge
                :value="$auth.impersonating()"
                color="orange"
                offset-x="9"
                offset-y="9"
                bordered
                bottom
                overlap
                dot
              >
                <v-avatar
                  size="32"
                >
                  <v-img :src="$auth.user().avatar" />
                </v-avatar>
              </v-badge>
            </v-btn>
          </template>
          <v-list
            class="py-0"
            dense
          >
            <v-list-item>
              <v-list-item-action
                class="mr-4"
              >
                <v-icon small>
                  fal fa-fw fa-language
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <language-switcher
                  class="text-caption mb-n4"
                  style="max-width: 120px"
                  outlined
                  dense
                />
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item
              v-if="$auth.check({ patients: ['phi','phi_toggle'] })"
              @click="hideData('patients', 'phi','phi_toggle')"
            >
              <v-list-item-action
                :key="$auth.check({ patients: 'phi' }) ? 'fa-eye-slash' : 'fa-eye'"
                class="mr-4"
              >
                <v-icon
                  small
                  v-text="$auth.check({ patients: 'phi' }) ? 'fal fa-fw fa-eye-slash' : 'fal fa-fw fa-eye'"
                />
              </v-list-item-action>
              <v-list-item-title
                v-text="$auth.check({ patients: 'phi' }) ? 'Hide PHI' : 'Show PHI'"
              />
            </v-list-item>
            <v-list-item
              v-if="$auth.check({ financials: ['view','view_toggle'] })"
              @click="hideData('financials', 'view','view_toggle')"
            >
              <v-list-item-action
                :key="$auth.check({ financials: 'view' }) ? 'fa-eye-slash' : 'fa-eye'"
                class="mr-4"
              >
                <v-icon
                  small
                  v-text="$auth.check({ financials: 'view' }) ? 'fal fa-fw fa-eye-slash' : 'fal fa-fw fa-eye'"
                />
              </v-list-item-action>
              <v-list-item-title
                v-text="$auth.check({ financials: 'view' }) ? 'Hide Financials' : 'Show Financials'"
              />
            </v-list-item>
            <v-divider />
            <template v-if="$auth.impersonating()">
              <v-list-item
                @click="$auth.unimpersonate({
                  redirect: { path: '/' },
                })"
              >
                <v-list-item-action class="mr-4">
                  <v-icon small>
                    fal fa-user-slash
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>Unimpersonate</v-list-item-title>
              </v-list-item>
              <v-divider />
            </template>
            <v-dialog
              v-else
              v-model="changePasswordDialog.open"
              max-width="400"
            >
              <template #activator="{ on }">
                <v-list-item
                  v-on="on"
                  @click="changePasswordDialog.open = true"
                >
                  <v-list-item-action class="mr-4">
                    <v-icon small>
                      fal fa-fw fa-lock
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Change Password</v-list-item-title>
                </v-list-item>
              </template>
              <v-card>
                <v-form
                  v-model="changePasswordDialog.valid"
                  @submit.prevent="changePassword"
                >
                  <v-card-title>Change Password</v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-text-field
                      v-model="changePasswordDialog.form.password_current"
                      label="Current Password"
                      prepend-icon="mdi-lock"
                      type="password"
                      :rules="rules.password_current"
                    />
                    <v-text-field
                      v-model="changePasswordDialog.form.password"
                      label="New Password"
                      name="password"
                      required
                      counter
                      minlength="8"
                      prepend-icon="mdi-fingerprint"
                      :append-icon="changePasswordDialog.show_password ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="changePasswordDialog.show_password ? 'text' : 'password'"
                      hint="At least 8 characters"
                      :rules="rules.password"
                      @click:append="changePasswordDialog.show_password = !changePasswordDialog.show_password"
                    />
                    <v-text-field
                      v-model="changePasswordDialog.form.password_confirmation"
                      label="Confirm Password"
                      name="password_confirmation"
                      type="password"
                      required
                      minlength="8"
                      prepend-icon="mdi-fingerprint"
                      :rules="rules.password_confirmation"
                    />
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      text
                      @click="changePasswordDialog.open = false"
                    >
                      {{ $t('Cancel') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      type="submit"
                      :loading="changePasswordDialog.loading"
                    >
                      {{ $t('Update') }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-list>
        </v-menu>
        <small
          class="ml-2"
        >
          {{ $auth.user().first_name }} {{ $auth.user().last_name }}
        </small>
        <v-spacer />
        <v-btn
          icon
          tile
          ripple
          small
          class="mr-n2"
          @click="$auth.logout()"
        >
          <i class="fal fa-sign-out-alt" />
        </v-btn>
      </v-toolbar>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState } from 'vuex';

  export default {
    components: {
      LanguageSwitcher: () => import('@/components/selects/LanguageSwitcher.vue'),
    },
    props: {
      opened: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        changePasswordDialog: {
          form: {},
          show_password: false,
          valid: false,
          open: false,
        },
        rules: {
          password_current: [
            v => !!v || 'Please enter your current password',
          ],
          password: [
            v => !!v || 'Please enter your new password',
            v => v?.length >= 8 || 'Password must have a minimum of 8 characters',
          ],
          password_confirmation: [
            v => !!v || 'Please re-enter your new password',
            v => v === this.changePasswordDialog.form.password || 'Passwords must match',
          ],
        },
        responsive: false,
      }
    },
    computed: {
      ...mapState('app', ['image', 'color', 'settings']),
      links() {
        if (this.$auth.user().provider) {
          return [
            {
              to: { name: 'Dashboard' },
              icon: 'fal fa-fw fa-objects-column',
              text: this.$t('Dashboard'),
            },
            {
              to: { name: 'Lab Results' },
              icon: 'fal fa-fw fa-microscope',
              text: this.$tc('Lab Result', 2),
            },
            {
              to: { name: 'Patients' },
              icon: 'fal fa-fw fa-bed',
              text: this.$options.filters.capitalize(this.$tc('patient', 2)),
              auth: { patients: 'phi' },
            },
            {
              to: { name: 'Education' },
              icon: 'fal fa-fw fa-graduation-cap',
              text: this.$options.filters.capitalize(this.$t('education')),
            },
          ]
        } else if (this.$auth.user().client) {
          return [
            {
              to: { name: 'Dashboard' },
              icon: 'fal fa-fw fa-objects-column',
              text: this.$t('Dashboard'),

            },
            {
              divider: true,
              subheader: this.$tc('OneChoice Report', 2),
              auth: { 'lab results': ['view'] },
            },
            {
              to: { name: 'Lab Results' },
              icon: 'fal fa-fw fa-microscope',
              text: this.$tc('Lab Result', 2),
              auth: { 'lab results': ['view'] },
            },
            {
              divider: true,
              subheader: 'Precision ASP',
              auth: { prescriptions: ['view'] },
            },
            {
              to: { name: 'Precision ASP' },
              icon: 'fal fa-fw fa-crosshairs',
              text: 'ASP Overview',
              auth: { prescriptions: ['view'] },
            },
            {
              divider: true,
            },
            {
              to: { name: 'Patients' },
              icon: 'fal fa-fw fa-bed',
              text: this.$options.filters.capitalize(this.$tc('patient', 2)),
              auth: { patients: 'phi' },
            },
            {
              to: { name: 'Providers' },
              icon: 'fal fa-fw fa-user-md',
              text: this.$options.filters.capitalize(this.$tc('provider', 2)),
            },
            {
              to: { name: 'Prescriptions' },
              icon: 'fal fa-fw fa-prescription',
              text: 'ABX Scripts',
              auth: { prescriptions: ['view'] },
            },
            {
                to: { name: 'Facilities' },
                icon: 'fal fa-fw fa-hospitals',
                text: this.$options.filters.capitalize(this.$tc('facility', 2)),
                auth: { facilities: ['view'] },
            },
            {
                to: '/reports',
                icon: 'fal fa-fw fa-file-chart-line',
                text: this.$options.filters.capitalize(this.$tc('report', 2)),
                auth: { reports: ['*', 'view', 'order'] },
            },
            {
                to: '/education',
                icon: 'fal fa-fw fa-graduation-cap',
                text: this.$options.filters.capitalize(this.$t('education')),
            },
            {
              divider: true,
              subheader: 'Admin',
              icon: 'fal fa-fw fa-sliders',
              value: this.adminMenu,
              dense: true,
              links: [
                {
                  to: { name: 'Users' },
                  icon: 'fal fa-fw fa-users',
                  text: this.$options.filters.capitalize(this.$tc('user', 2)),
                  auth: { users: ['view', 'edit', 'add'] },
                },
              ],
            },
          ]
        } else {
          return [
            {
              to: { name: 'Dashboard' },
              icon: 'fal fa-fw fa-objects-column',
              text: this.$t('Dashboard'),
            },
            {
                to: { name: 'Lab Results' },
                icon: 'fal fa-fw fa-microscope',
                text: this.$tc('Lab Result', 2),
                auth: { 'lab results': ['view', 'interpret'], 'interpretations': ['approve'] },
            },
            {
                to: { name: 'Prescriptions' },
                icon: 'fal fa-fw fa-prescription',
                text: this.$options.filters.capitalize(this.$tc('prescription', 2)),
                auth: { prescriptions: ['view'] },
            },
            {
                to: { name: 'Patients' },
                icon: 'fal fa-fw fa-bed',
                text: this.$options.filters.capitalize(this.$tc('patient', 2)),
                auth: { patients: 'phi' },
            },
            {
                to: { name: 'Providers' },
                icon: 'fal fa-fw fa-user-md',
                text: this.$options.filters.capitalize(this.$tc('provider', 2)),
            },
            {
                to: { name: 'Facilities' },
                icon: 'fal fa-fw fa-hospitals',
                text: this.$options.filters.capitalize(this.$tc('facility', 2)),
                auth: { facilities: ['*', 'view', 'edit'] },
            },
            {
                to: '/reports',
                icon: 'fal fa-fw fa-file-chart-line',
                text: this.$options.filters.capitalize(this.$tc('report', 2)),
                auth: { reports: ['*', 'view', 'order'] },
            },
            {
                divider: true,
                subheader: this.$options.filters.capitalize(this.$t('Admin')),
                icon: 'fal fa-fw fa-sliders',
                value: this.adminMenu,
                dense: true,
                links: [
                {
                    to: { name: 'Organisms' },
                    icon: 'fal fa-fw fa-disease',
                    text: this.$options.filters.capitalize(this.$tc('organism', 2)),
                    auth: { organisms: ['edit', 'view'] },
                },
                {
                    to: { name: 'Resistances' },
                    icon: 'fal fa-fw fa-shield-virus',
                    text: this.$options.filters.capitalize(this.$tc('resistance', 2)),
                    auth: { resistances: ['edit', 'view'] },
                },
                {
                    to: { name: 'Allergens' },
                    icon: 'fal fa-fw fa-allergies',
                    text: this.$options.filters.capitalize(this.$tc('allergen', 2)),
                    auth: { allergens: ['edit', 'view'] },
                },
                {
                    to: { name: 'Diagnosis Codes' },
                    icon: 'fal fa-fw fa-diagnoses',
                    text: this.$options.filters.capitalize(this.$tc('diagnosis', 2)),
                    // text: 'Diagnosis Codes',
                    auth: { diagnoses: ['edit', 'view'] },
                },
                {
                    to: { name: 'Indications' },
                    icon: 'fal fa-fw fa-biohazard',
                    text: this.$options.filters.capitalize(this.$tc('indication', 2)),
                    auth: { indications: ['edit', 'view'] },
                },
                {
                    to: { name: 'Drugs' },
                    icon: 'fal fa-fw fa-pills',
                    text: this.$options.filters.capitalize(this.$tc('drug', 2)),
                    auth: { drugs: ['edit', 'view'] },
                },
                {
                    to: { name: 'Formularies' },
                    icon: 'fal fa-fw fa-prescription-bottle-pill',
                    text: this.$options.filters.capitalize(this.$tc('formulary', 2)),
                    auth: { drugs: ['edit', 'view'] },
                },
                {
                    to: { name: 'Instructions' },
                    icon: 'fal fa-fw fa-message-lines',
                    text: this.$options.filters.capitalize(this.$tc('instruction', 2)),
                    auth: { instructions: ['edit', 'view'] },
                },
                {
                    to: { name: 'References' },
                    icon: 'fal fa-fw fa-books',
                    text: this.$options.filters.capitalize(this.$tc('reference', 2)),
                    auth: { references: ['edit', 'view'] },
                },
                {
                    to: { name: 'Newsletters' },
                    icon: 'fal fa-fw fa-newspaper',
                    text: this.$options.filters.capitalize(this.$tc('newsletter', 2)),
                    auth: { newsletters: ['edit', 'view'] },
                },
                {
                    to: { name: 'Clients' },
                    icon: 'fal fa-fw fa-buildings',
                    text: this.$options.filters.capitalize(this.$tc('client', 2)),
                    auth: { clients: ['edit', 'view'] },
                },
                {
                    to: { name: 'Users' },
                    icon: 'fal fa-fw fa-users',
                    text: this.$options.filters.capitalize(this.$tc('user', 2)),
                    auth: { users: ['view', 'edit', 'add'] },
                },
                {
                    to: { name: 'Packages' },
                    icon: 'fal fa-fw fa-box',
                    text: 'Packages',
                    auth: { packages: ['view', 'edit', 'add'] },
                },
                {
                    to: { name: 'Features' },
                    icon: 'fal fa-fw fa-list',
                    text: 'Features',
                    auth: { features: ['view', 'edit', 'add'] },
                },
                {
                    to: { name: 'System' },
                    icon: 'fal fa-fw fa-cogs',
                    text: 'System',
                    auth: { queue: ['view'], financials: ['view'] },
                },
              ],
            },
          ]
        }
      },
      inputValue: {
        get () {
          return this.$store.state.app.drawer
        },
        set (val) {
          this.setDrawer(val)
        },
      },
      adminMenu: {
        get () {
          return this.$store.state.app.admin_menu
        },
        set (val) {
          this.setAdminMenu(val)
        },
      },
    },
    mounted() {
      if(this.settings.hide_patients){
        let index = this.$auth.user().roles['patients'].indexOf('phi');
        this.replaceRol('patients', 'phi_toggle', index)
      }
      if(this.settings.hide_financials){
        let index = this.$auth.user().roles['financials'].indexOf('view');
        this.replaceRol('financials', 'view_toggle', index)
      }
    },
    methods: {
      ...mapMutations('app', ['setDrawer', 'toggleDrawer', 'setSettings']),
      changePassword () {
        if (this.changePasswordDialog.valid) {
          this.changePasswordDialog.loading = true
          this.axios.patch('admin/users/' + this.$auth.user().uuid, this.changePasswordDialog.form)
            .then(() => {
              this.$toast.success('Password updated')
              this.changePasswordDialog.open = false
              this.changePasswordDialog.form = {}
            })
            .catch(error => {
              if (error.response?.data?.message) {
                this.$toast.error(error.response.data.message)
              }
            })
            .finally(() => {
              this.changePasswordDialog.loading = false
            })
        }
      },
      replaceRol(role, on, index) {
        this.$auth.user().roles[role].splice(index, 1);
        this.$auth.user().roles[role].push(on);
      },
      hideData (role, on, off) {

        var index = this.$auth.user().roles[role].indexOf(on);
        if (index !== -1) {
          this.replaceRol(role, off, index)
          this.$set(this.$store.state.app.settings, `hide_${role}`, true)
          this.setSettings(this.$store.state.app.settings)
        } else {
          index = this.$auth.user().roles[role].indexOf(off);
          this.replaceRol(role, on, index)
          this.$delete(this.$store.state.app.settings, `hide_${role}`)
          this.setSettings(this.$store.state.app.settings)
        }
      },
    },
  }
</script>
